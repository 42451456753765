<template>
  <header ref="header" class="py-[1.25rem] fixed top-0 z-40 inset-x-0">
    <div class="container_fluid container z-20 relative flex flex-row justify-between md:items-center">
      <div class="navbar_left">
        <a class="logo_container @apply w-[3.441rem] block md:w-[5rem]; animate_first" href="/">
          <span class="sr-only">Website link</span>
          <img alt="" class="src" height="43.53" src="~/assets/images/logo.svg" width="80">
        </a>
        <template v-if="!isBusiness">
          <NuxtLink v-for="link in navigationLinks" :key="link.name" :to="link.link"
            :class="['head_link', custom_bg_text_style]">
            {{ link.name }}
          </NuxtLink>
        </template>

        <template v-else>
          <a v-for="link in navigationLinks" :href="`/business${link.link}`" :key="link.name"
          :class="[$route.fullPath === `/business${link.link}` ? '!text-primary-2' : null]"> {{ link.name }}</a>
        </template>
      </div>
      <div class="navbar_right flex items-center">
        <a :class="[custom_bg_text_style]"
          class="head_link hidden md:inline-block font-heading font-bold hover:underline ease-in duration-200 login_link"
          href="https://app.apexnetwork.co/login">
          Login
        </a>
        <a class="p-1 create_account_btn bg-orange-1 hover:bg-primary-2 ease-in duration-200 font-heading text-center fixed md:relative z-50 text-[.9375rem] md:text-base bottom-0 w-full md:w-auto inset-x-0 font-bold py-[1.0625rem] px-6 text-white md:rounded-xl md:ml-10 create_acc_btn"
          href="https://app.apexnetwork.co/create-account">
          Open an Account
        </a>
      </div>
      <div :class="{ 'active': isMenu }"
        class="hamburger_container h-10 w-8 flex md:hidden flex-col justify-center items-center" role="button"
        @click="toggleMenu">
        <div v-for="i in 3" :key="i"
          :class="['hamburger_container_icon_stroke', isMenu ? 'bg-header' : custom_bg_text_style]"
          class="hamburger_container_icon_stroke h-[2px] w-full rounded-[1px]"></div>
      </div>
    </div>
    <div :class="{ 'opacity-100 translate-x-[0] md:translate-x-[100%] active': isMenu }"
      class="mobilemenu_container bg-[#FFF5E1] shadow-one flex flex-col opacity-0 gap-[1.9375rem] md:hidden p-[1.25rem] pt-[4.5rem] fixed inset-0">
      <div class="menu_links_container flex flex-col items-start gap-5 mt-5">
        <template v-if="isBusiness">
          <a v-for="link in navigationLinks" :key="link.name" :href="`/business${link.link}`" class="inline-block" @click="toggleMenu"
            :class="[$route.fullPath === `/business${link.link}` ? 'text-primary-2' : null]">
            {{ link.name }}
          </a>
        </template>
        <NuxtLink v-else v-for="link in navigationLinks" :key="link.name" :to="link.link" class="inline-block"
          @click="toggleMenu" :class="['head_link', custom_bg_text_style]">
          {{ link.name }}
        </NuxtLink>
        <a class="font-body font-bold login_link" href="https://app.apexnetwork.co/login">Login</a>
      </div>
      <div class="flex flex-col gap-4">
        <p class="font-semibold">Download the app now</p>
        <StoreButtonsContainer />
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
const props = defineProps({
  isBusiness: {
    type: Boolean,
    default: false,
    required: false
  }
})

const navLinks = [
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Rates",
    link: "/market-rates/gift-card",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Business",
    link: "/business",
  },
];
const businessNavLinks = [
  {
    name: "Business",
    link: "#",
  },
  {
    name: "Services",
    link: "#services",
  },
  {
    name: "Testimonials",
    link: "#testimonials",
  },
];

const navigationLinks = computed(() => {
  return props.isBusiness ? businessNavLinks : navLinks
})

const header = ref<HTMLDivElement | null>(null)
const toggleHeader = () => {
  if (header.value) {
    if (window.scrollY > header.value?.offsetTop) {
      header.value.classList.add("header_sticky");
    } else {
      header.value.classList.remove("header_sticky");
    }
  } else return
}
const route = useRoute();
const isMenu = ref(false)
const toggleMenu = () => {
  isMenu.value = !isMenu.value;
}

onMounted(() => {
  window.addEventListener('scroll', () => {
    toggleHeader()
  })
  toggleHeader()
})

const custom_bg_text_style = ref(route.meta.custom_bg_text_style) ?? ''

watch(() => route, () => {
  isMenu.value = false;
})

watch(() => route.meta.custom_bg_text_style, () => {
  custom_bg_text_style.value = route.meta.custom_bg_text_style ?? ''
})
</script>

<style lang="postcss" scoped>
.logo_container {
  @apply w-[3.441rem] block md:w-[5rem];
}

header {
  @apply py-[1.25rem] fixed top-0 z-40 inset-x-0;
  background: transparent;
  transition: .2s ease-in-out;

  &.header_sticky {
    @apply bg-white z-40 shadow-one;

    a.black_bg_text {
      @apply !text-body
    }

    .hamburger_container_icon_stroke.black_bg_text {
      @apply bg-black;
    }
  }

  .container_fluid {
    @apply container z-20 relative flex flex-row justify-between md:items-center;

    .navbar_left {
      @apply flex items-center;

      a {
        @apply inline-block mr-10 font-body text-header hover:text-primary-2;

        &.black_bg_text {
          @apply text-white;
        }

        &:nth-child(n+2) {
          @apply hidden md:inline-block p-2 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none;
          ;

          &.router-link-active {
            @apply text-primary-2;
          }
        }
      }
    }

    .navbar_right {
      @apply flex items-center;

      .login_link {
        @apply hidden md:inline-block font-heading font-bold hover:underline ease-in duration-200;
      }

      .create_account_btn {
        @apply md:ml-10;
      }
    }
  }

  .mobilemenu_container {
    @apply bg-[#FFF5E1] shadow-one flex flex-col opacity-0 gap-[1.9375rem] md:hidden p-[1.25rem] pt-[4.5rem] fixed inset-0;
    transform: translateX(100%);
    transition: .5s cubic-bezier(.455, .03, .515, .955);

    &.active {
      @apply opacity-100 translate-x-[0] md:translate-x-[100%];
    }

    .menu_links_container {
      @apply flex flex-col items-start gap-5 mt-5;

      a {
        @apply inline-block;

        &.router-link-active {
          @apply text-primary-2;
        }
      }

      .login_link {
        @apply font-body font-bold;
      }
    }
  }
}

.hamburger_container {
  transition: all .3s cubic-bezier(.455, .03, .515, .955);

  &.active {
    .hamburger_container_icon_stroke {
      &:first-of-type {
        -webkit-transform: translateY(7px) rotate(45deg);
        transform: translateY(7px) rotate(45deg);
      }

      &:last-of-type {
        -webkit-transform: translateY(-7px) rotate(45deg);
        transform: translateY(-7px) rotate(-45deg);
        margin-top: .3125rem;
      }

      &:nth-child(2) {
        opacity: 0;
      }
    }
  }

  .hamburger_container_icon_stroke {
    @apply h-[2px] bg-header w-full rounded-[1px];
    transition: all .3s cubic-bezier(.455, .03, .515, .955);

    &.black_bg_text {
      @apply bg-white;
    }

    &:nth-child(n+2) {
      @apply mt-1.5;
    }
  }
}
</style>